<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Tambah Program Guru
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
                <b-form-group label="Tahun Akademik" label-for="academic_year_id"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="academic_year_id" v-model="dataParent.academic_year_id"
                    :reduce="(academic_year_id) => academic_year_id.id" placeholder="Tahun Akademik"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="academicYear" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="academic_subject_id" rules="required">
                <b-form-group label="Mata Pelajaran" label-for="academic_subject_id"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="academic_subject_id" v-model="dataParent.academic_subject_id"
                    :reduce="(academic_subject_id) => academic_subject_id.id" placeholder="Mata Pelajaran"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="academicSubject" label="subject" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="academic_class_id" rules="required">
                <b-form-group label="Kelas" label-for="academic_class_id" :state="errors.length > 0 ? false : null">
                  <v-select id="academic_class_id" v-model="dataParent.academic_class_id"
                    :reduce="(academic_class_id) => academic_class_id.id" placeholder="Kelas"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="academicClass" label="description" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Prosem 1" label-for="Prosem 1">
                <validation-provider #default="{ errors }" name="prosem_one" rules="required">
                  <b-form-file id="prosem_one" v-model="dataParent.prosem_one" type="file"
                    :state="errors.length > 0 ? false : null" placeholder="Prosem 1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>


            </b-col>
            <b-col md="6">

              <b-form-group label="Prosem 2" label-for="Prosem 2">
                <validation-provider #default="{ errors }" name="prosem_two" rules="required">
                  <b-form-file id="prosem_two" v-model="dataParent.prosem_two" type="file"
                    :state="errors.length > 0 ? false : null" placeholder="Prosem 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Program Tahunan" label-for="Program Tahunan">
                <validation-provider #default="{ errors }" name="annual_program" rules="required">
                  <b-form-file id="annual_program" v-model="dataParent.annual_program" type="file"
                    :state="errors.length > 0 ? false : null" placeholder="Program Tahunan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>


              <validation-provider #default="{ errors }" name="teacher_id" rules="required">
                <b-form-group label="Guru" label-for="teacher_id" :state="errors.length > 0 ? false : null">
                  <v-select id="teacher_id" v-model="dataParent.teacher_id" :reduce="(teacher_id) => teacher_id.id"
                    placeholder="Guru" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="teacherList"
                    label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-form-group label="Tanggal dibuat" label-for="date">
                <validation-provider #default="{ errors }" name="date" rules="required">
                  <b-form-input id="date" v-model="dataParent.date" type="date"
                    :state="errors.length > 0 ? false : null" placeholder="Tanggal dibuat" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { BFormFile } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
    vSelect,
    BFormFile,
  },
  data() {
    return {
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        academic_year_id: '',
        academic_subject_id: '',
        academic_class_id: '',
        prosem_one: '',
        prosem_two: '',
        annual_program: '',
        teacher_id: '',
        date: '',
      },
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
      academicYear: [],
      academicSubject: [],
      academicClass: [],
      teacherList: [],
    }
  },
  created() {
    this.getAcademicYears()
    this.getacademicSubject()
    this.getacademicClass()
    this.getTeacherlist()
  },
  computed: {
  },
  methods: {
    async getAcademicYears() {
      try {
        const response = await this.$http.get('/academicyears')
        this.academicYear = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Academic Not Success')
        console.log(err)
      }
    },
    async getacademicSubject() {
      try {
        const response = await this.$http.get('/academicsubjects?status=all')
        this.academicSubject = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Academic Subject Not Success')
        console.log(err)
      }
    },
    async getacademicClass() {
      try {
        const response = await this.$http.get('/academicclasses')
        this.academicClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Academic Class Not Success')
        console.log(err)
      }
    },
    async getTeacherlist() {
      try {
        const response = await this.$http.get('/employees')
        this.teacherList = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/teacherprograms', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'program-guru' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
